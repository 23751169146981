import * as TogglePrimitive from "@radix-ui/react-toggle";
import type { VariantProps } from "cva";
import * as React from "react";

import { cx } from "#app/utils/cva.ts";

import { buttonVariants } from "./button";

const Toggle = React.forwardRef<
	React.ElementRef<typeof TogglePrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof buttonVariants>
>(({ className, $variant = "translucent", $size, pressed, ...props }, ref) => (
	<TogglePrimitive.Root
		className={cx(buttonVariants({ $variant: pressed ? "default" : "translucent", $size, className }))}
		ref={ref}
		{...props}
	/>
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle };
